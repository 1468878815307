<template>
  <div class="d-flex justify-content-center align-items-center">
    <!--add button questionBank-->
    <ek-dialog placeholder="ابحث عن سؤال" class="w-100 full-width d-flex justify-content-start" @search="setSearch"> </ek-dialog>
    <b-button variant="primary" class="w-40" @click="goToNewQuiz">
      سؤال جديد<unicon class="ml-1" name="plus" width="15" height="15" fill="#fff"
    /></b-button>
  </div>
</template>

<script>
export default {
  methods: {
    goToNewQuiz() {
      this.$router.push({
        path: "/admin/questionBank/0",
      });
    },
    setSearch(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["order", "qusetion", "lessonOrder", 'lessonType'],
        query,
      });
    },
  },
};
</script>

<style>
.vgt-checkbox-col{
    background: transparent !important;
}
</style>
